import React, { useEffect } from 'react';
import { navigate } from 'gatsby'
import { Box, Card, CardHeader, CardBody, Heading,  Image, Paragraph } from 'grommet';
import { Button } from '@appkit4/react-components/button';
import AOS from 'aos';
import Footer from '../components/Footer';

export default function GetStarted( location ) {
  const personaCSA = () => {
    navigate('/cloud-security-architect')
  }
  const personaDSA = () => {
    navigate('/data-security-analyst')
  }
  const personaCDIR = () => {
    navigate('/cyber-defense-incident-responder')
  }
  const personaIA = () => {
    navigate('/internal-auditor')
  }
  const personaRA = () => {
    navigate('/risk-analyst')
  }
  const personaCTA = () => {
    navigate('/compliance-testing-analyst')
  }

  useEffect(() => {
    AOS.init({
      duration: 1200,
    });
  });

  // useEffect(() => {
  //   document.querySelector('html').style.scrollBehavior = 'auto'
  //   window.scroll({ top: 0 })
  //   document.querySelector('html').style.scrollBehavior = ''
  // }, [location.pathname]); // triggered on route change

  return (
    <Box
      direction="column"
    >
      <Box pad={{horizontal: "large"}} style={{paddingTop: '40px', minHeight: '84vh'}} >
        <Box direction="column" justify="between">
          <Heading level={3} margin={{bottom: "5px"}} style={{color: '#474747'}}>Discover</Heading>
          <Heading level={2} margin={{bottom: "20px"}} style={{color: '#474747', fontSize: '48px', letterSpacing: '-1px', marginLeft: '-2px'}}>ProEdge <span style={{color: '#d04a02'}}>Professionals</span></Heading>
          <Heading level={5} margin={{bottom: "medium"}} style={{color: '#474747', fontWeight: 'normal', maxWidth: '520px'}}>Learn more about ProEdge and explore the benefits for your people and your organization, as you prepare to meet tomorrow’s challenges and take advantage of tomorrow’s opportunities. <strong>Let's meet our professionals.</strong>
          </Heading>
        </Box>
        <Box margin={{bottom: '10px'}} pad={{top: '10px', bottom: '20px'}} direction="row" alignContent="center" gap="medium" overflow={{horizontal: 'scroll'}} >
          <Box className="ap-panel-with-extra-container">
            <Card height="360px" width="260px" background="light-1" style={{borderRadius: '.5em', boxShadow: 'none'}}>
              <CardHeader><Image fill="horizontal" src="https://images.ctfassets.net/fwgo2d14oeir/5lGujZlKCnqAx5mmNBZ1NJ/78243d7bca32e09e1b19c648396852e5/ia-avatar3.png" /></CardHeader>
              <CardBody pad="medium">
                <Heading level={4}>Internal Auditor</Heading>
                <Paragraph style={{fontSize: '14px'}}>Ensures internal controls and risk management processes are functioning properly​.</Paragraph><br />
                <Button kind='negative' onClick={personaIA}>Learn more</Button>
              </CardBody>
            </Card>
          </Box>
          <Box className="ap-panel-with-extra-container">
            <Card height="360px" width="260px" background="light-1" style={{borderRadius: '.5em', boxShadow: 'none'}}>
              <CardHeader><Image fill="horizontal" src="https://images.ctfassets.net/fwgo2d14oeir/3gEuLRINUPfiKYtKx2QKUn/02745b4002892f535e70f965586ba2b0/asian-female-avatar2.png" /></CardHeader>
              <CardBody pad="medium">
                <Heading level={4}>Risk Analyst</Heading> 
                <Paragraph style={{fontSize: '14px'}}>Evaluate potential risks, develop risk mitigation strategies, and manage them​.</Paragraph><br />
                <Button kind='negative' onClick={personaRA}>Learn more</Button>
              </CardBody>
            </Card>
          </Box>
          <Box className="ap-panel-with-extra-container">
            <Card height="360px" width="260px" background="light-1" style={{borderRadius: '.5em', boxShadow: 'none'}}>
              <CardHeader><Image fill="horizontal" src="https://images.ctfassets.net/fwgo2d14oeir/4ldKQvsPZ9f7nWi9L8NFMz/1c08618a683f3621527db8d93f5f3dd3/ra-avatar.png" /></CardHeader>
              <CardBody pad="medium">
                <Heading level={4}>Compliance Testing Analyst</Heading>
                <Paragraph style={{fontSize: '14px'}}>Performs assessments to ensure compliance with relevant laws.</Paragraph><br />
                <Button kind='negative' onClick={personaCTA}>Learn more</Button>
              </CardBody>
            </Card>
          </Box>
          <Box className="ap-panel-with-extra-container">
          <Card height="360px" width="260px" background="light-1" style={{borderRadius: '.5em', boxShadow: 'none'}} data-aos="fade-up">
              <CardHeader><Image fill="horizontal" src="https://images.ctfassets.net/fwgo2d14oeir/62PpHLyGFqTw5NCFGrAq8D/e71cc1e986c653616753bc0d6595c7e7/pe-persona-architect2.jpg" /></CardHeader>
              <CardBody pad="medium">
                <Heading level={4}>Cloud Security Architect</Heading>
                <Paragraph style={{fontSize: '14px'}}>Design & builds security patterns for cloud-based computing and data storage systems.</Paragraph><br />
                <Button kind='negative' onClick={personaCSA}>Learn more</Button>
              </CardBody>
            </Card>
          </Box>
          <Box className="ap-panel-with-extra-container">
            <Card height="360px" width="260px" background="light-1" style={{borderRadius: '.5em', boxShadow: 'none'}}>
              <CardHeader><Image fill="horizontal" src="https://images.ctfassets.net/fwgo2d14oeir/TYgmaO9I9j6vtw6IRCTor/daebd6175344abfc9f1326e7b5e9b8ac/pe-persona-analyst.jpg" /></CardHeader>
              <CardBody pad="medium">
                <Heading level={4}>Data Security Analyst</Heading>
                <Paragraph style={{fontSize: '14px'}}>Understands trending security risks and make recommendations to enhance data security.</Paragraph><br />
                <Button kind='negative' onClick={personaDSA}>Learn more</Button>
              </CardBody>
            </Card>
          </Box>
          <Box className="ap-panel-with-extra-container">
            <Card height="360px" width="260px" background="light-1" style={{borderRadius: '.5em', boxShadow: 'none'}}>
              <CardHeader><Image fill="horizontal" src="https://images.ctfassets.net/fwgo2d14oeir/1oWWofUQu3mi7VyJmNHQAu/b3b2cf171bb974712b11583b3a54f0c9/pe-persona-responder.jpg" /></CardHeader>
              <CardBody pad="medium">
                <Heading level={4}>Cyber Defense Incident Responder</Heading>
                <Paragraph style={{fontSize: '14px'}}>Investigates and responds to cyber threats against networks.</Paragraph><br />
                <Button kind='negative' onClick={personaCDIR}>Learn more</Button>
              </CardBody>
            </Card>
          </Box>
          
        </Box>
      </Box>

      <Footer />
    </Box>
  );
}